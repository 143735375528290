<template>
<div> DEBUGGER ENABLED </div>
</template>

<script>
export default {
name: "debug-component",
beforeCreate() {
 console.log('beforeCreate', process.env.npm_package_scripts_build_server)
},
created() {
 console.log('created', process.env.npm_package_scripts_build_server)
},
beforeMount() {
 console.log('created', process.env.npm_package_scripts_build_server)
},
mounted() {
 console.log('mounted', process.env.npm_package_scripts_build_server)
},
beforeUpdate() {
 console.log('beforeUpdate', process.env.npm_package_scripts_build_server)
},
updated() {
 console.log('updated', process.env.npm_package_scripts_build_server)
},
beforeDestroy() {
 console.log('beforeDestroy', process.env.npm_package_scripts_build_server)
},
destroyed() {
 console.log('destroyed', process.env.npm_package_scripts_build_server)
},
preFetch() {
 console.log('preFetch', process.env.npm_package_scripts_build_server)
}
}
</script>