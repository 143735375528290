<template>
    <div class="bg-dark pt-5 text-secondary">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 py-3"> <a href="#" class="d-inline-block fw-bold h2 mb-4 text-decoration-none text-white">Site Viewer</a>
                            <p class="mb-3">Smart tool for easy live site preview</p>
                            <div class="mb-4">                              <br/>
                              <a href= "mailto:moaddib666@gmail.com?subject=Feedback: sitepreviewer.live">Contact us</a>
                            </div>
                        </div>
                    </div>
                    <div class="pb-3 pt-3 small">
                        <hr class="border-secondary mt-0"/>
                        <div class="align-items-center row">
                            <div class="col-md pb-2 pt-2">
                                <p class="mb-0">&copy; 2021 | All Rights Reserved - <a href="#">Site Viewer</a></p>
                            </div>
                            <div class="col-md-auto pb-2 pt-2"> |
                            </div>
                        </div>
                    </div>
                </div>
            </div>

</template>

<script>
export default {
  name: "footerComponent"
}
</script>

<style scoped>

</style>