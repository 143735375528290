<template>
   <header style="background-color: rgba(255, 255, 255, 0.6);" class="fixed-top">
            <nav class="border-bottom navbar navbar-expand-lg navbar-light pb-lg-0 pt-lg-0">
                <div class="container"> <a class="fw-bold navbar-brand" href=".">Site Viewer</a>
                    <ul class="align-items-lg-center flex-row ms-auto me-2 me-lg-0 navbar-nav order-lg-2">
                        <li class="d-none d-sm-block nav-item">
</li>
                        <li class="ms-2 nav-item">
</li>
                    </ul>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown-3" aria-controls="navbarNavDropdown-3" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
        </header>
</template>

<script>
export default {
  name: "headerComponent"
}
</script>

<style scoped>

</style>