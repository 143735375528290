<template>
  <div class="integrations-holder">
    <div class="cpanel-logo" > </div>
    <h3> Integrations </h3>
    <p> SitePreviewer has cPanel support</p>
  </div>
</template>

<script>
export default {
  name: "integrations"
}
</script>

