<template>
    <div id="app">
        <div class="main">
            <router-view></router-view>
        </div>
      <footer-component></footer-component>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import HeaderComponent from "./modules/header.vue";
    import FooterComponent from "./modules/footer.vue";
    export default {
      name: 'App',
      components: {FooterComponent, HeaderComponent},
      metaInfo: {
            meta: [
                { charset: 'utf-8' },
            ]
        },

        computed: {
            ...mapGetters({
                title: 'title',
            })
        },
    };
</script>
