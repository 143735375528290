<template>
  <div class="form-holder">
    <b-form :autocomplete="autocomplete" @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        label="Link to your awesome website:"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          class="form-control form-control-lg ps-4 pe-4 rounded-pill"
          v-model="form.site_url"
          placeholder="https://my.awesome.website"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-2" label="Website host:" label-for="input-2">
        <b-form-input
          class="form-control form-control-lg ps-4 pe-4 rounded-pill"
          id="input-2"
          v-model="form.host"
          placeholder="server1.host.com | 10.20.31.44"
          required
        ></b-form-input>
      </b-form-group>
      <b-button :disabled="!formReady" class="rounded-pill" type="submit" variant="primary">Preview</b-button>
    </b-form>
  </div>
</template>

<script>
  import DebugComponent from "./debuger.vue";
  export default {
    name: 'SiteForm',
    components: {DebugComponent},
    data() {
      return {
        form: {
          site_url: '',
          host: '',
        },
        show: true,
        autocomplete: 'off',
      }
    },
    created() {
      this.endableAutoFill();
    },
    mounted() {
    },
    methods: {
      endableAutoFill() {
        setTimeout(() => {
            this.autocomplete = 'on';
          }, 1200);
      },
      onSubmit(event) {
        event.preventDefault()
        // FIXME workaound
        this.form.site_url = this.form.site_url.replace(/\/$/, "");
        const data = {
          "ttl_seconds": 3600,
          ...this.form
        }
        const payload = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }

        fetch("api/v1/site/create", payload).then((r) => {
          r.json().then((data) => {
            // window.location.href = data.link
            window.open(data.link, '_blank');
          }).catch((err) => {
            console.log({err: err});
          })
        })
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.site_url = ''
        this.form.host = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    },
    computed: {
      formReady() {
        return this.form.site_url && this.form.host
      },
    }
  }
</script>

