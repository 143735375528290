<template>
<!--    <div>-->
<!--        <router-link to="/about">Go to About page</router-link>-->
<!--        <users-list :users="users"></users-list>-->
<!--    </div>-->

        <main>
            <section class="background-cover bg-dark  pb-5 position-relative pt-5 text-white landing">
                <div class="container mt-5  pb-5 pt-5">
                    <div class="pb-5 pt-5 row">
                        <div class="col-lg-7 col-xl-6 col-xxl-5 pb-5 pt-5">
                            <h1 class="display-3 fw-normal ">Preview your Website</h1>
                            <p class="lead mb-5">Visit the website before DNS propagation or changing resource records</p>
                            <site-form></site-form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
</template>

<script>
    import {mapGetters} from 'vuex';
    import UsersList from './components/users-list/index.vue';
    import SiteForm from "./components/site-form.vue";
    import Integrations from "./components/integrations.vue";

    export default {
        name: 'Home',

        metaInfo: {
            title: 'Website Preview',
            meta: [
                { name: 'description', content: 'Application that allow preview websites without changing DNS configuration' }
            ]
        },

        components: {
          SiteForm,
          UsersList,
        },

        computed: {
            ...mapGetters({
                users: 'users',
            })
        },

        // Server-side only
        // This will be called by the server renderer automatically
        serverPrefetch () {
            // return the Promise from the action
            // so that the component waits before rendering
            return this.getUsers();
        },

        // Client-side only
        mounted () {
            // If we didn't already do it on the server, we fetch the users
            if (!this.users.length) {
                this.getUsers();
            }
        },

        methods: {
            getUsers () {
                return this.$store.dispatch('getUsers');
            }
        }
    };
</script>

